exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-mini-index-js": () => import("./../../../src/pages/mini/index.js" /* webpackChunkName: "component---src-pages-mini-index-js" */),
  "component---src-pages-mini-send-file-js": () => import("./../../../src/pages/mini/send-file.js" /* webpackChunkName: "component---src-pages-mini-send-file-js" */),
  "component---src-pages-order-confirmation-jsx": () => import("./../../../src/pages/order-confirmation.jsx" /* webpackChunkName: "component---src-pages-order-confirmation-jsx" */),
  "component---src-pages-pro-book-call-js": () => import("./../../../src/pages/pro/book-call.js" /* webpackChunkName: "component---src-pages-pro-book-call-js" */),
  "component---src-pages-pro-index-js": () => import("./../../../src/pages/pro/index.js" /* webpackChunkName: "component---src-pages-pro-index-js" */),
  "component---src-pages-realizacje-jsx": () => import("./../../../src/pages/realizacje.jsx" /* webpackChunkName: "component---src-pages-realizacje-jsx" */),
  "component---src-pages-standard-index-js": () => import("./../../../src/pages/standard/index.js" /* webpackChunkName: "component---src-pages-standard-index-js" */),
  "component---src-pages-standard-send-file-js": () => import("./../../../src/pages/standard/send-file.js" /* webpackChunkName: "component---src-pages-standard-send-file-js" */)
}

